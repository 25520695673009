@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-black-200;
  font-family: "Nunito Sans", sans-serif;
}

.monda {
  font-family: "Monda", sans-serif;
}

.nunito-sans {
  font-family: "Nunito Sans", sans-serif;
}

.montserrat-alternates {
  font-family: "Montserrat Alternates", sans-serif;
}

.loader {
  @apply inline-flex justify-between items-center w-10;
}

.loader div {
  @apply w-2 h-2 rounded-full;
  animation: loader 2s forwards infinite;
}

.loader div + div {
  animation-delay: 0.5s;
}

.loader div + div + div {
  animation-delay: 1s;
}

@keyframes loader {
  0% {
    @apply opacity-50;
  }
  50% {
    @apply opacity-100;
  }
  100% {
    @apply opacity-50;
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 100px;
  height: 100px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 84px;
  height: 84px;
  margin: 8px;
  border-radius: 50%;
  border: 8px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ql-editor {
  min-height: 200px;
}

.ql-editor p {
  margin-top: 12px !important;
}

.react-select-container {
  @apply rounded-sm border border-white-400 !important;
}
